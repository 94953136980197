<template>
	<Modal class="modal--register" :auth-modal="true" @close="redirectBack">
		<template #header>
			<VHeading level="2">Login to your Takwimu account</VHeading>
		</template>
		<template #content>
			<div class="modal-section__card__content__inner">
				<VText
					size="big"
					weight="bold"
					classes="modal-section__card__content__inner__title"
				>
					Login by email
				</VText>

				<form class="form__column" @submit.prevent="submit">
					<VDefaultField :error="errors.get('email')">
						<template #field>
							<VInput
								v-model="form.email"
								type="email"
								placeholder="Email"
								:error="errors.get('email')"
								@input="errors.clear('email')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField :error="errors.get('password')">
						<template #field>
							<VInput
								v-model="form.password"
								type="password"
								placeholder="Password"
								:error="errors.get('password')"
								@input="errors.clear('password')"
							/>
						</template>
					</VDefaultField>

					<VLink size="small" align="right" @click="openResetPasswordModal">
						Forgotten your password ?
					</VLink>

					<div class="form__group mt-5">
						<small class="invalid" v-if="errors.get('detail')">
							<VText size="small" weight="bold">{{
								errors.get('detail')
							}}</VText>
						</small>
					</div>

					<VButton color="primary" type="submit">
						Login
					</VButton>
				</form>
			</div>
			<SocialAuth type="Login"/>
		</template>
	</Modal>
</template>

<script>
import { mapState } from 'vuex';
import { LOGIN } from '@/store/actions.type';
import { SET_ACTIVE_MODAL, CLEAR_ERRORS } from '@/store/mutations.type';

import modal from '@/mixins/modal';

export default {
	name: 'LoginModal',
  mixins: [modal],
	data() {
		return {
			form: {
				email: '',
				password: ''
			}
		};
	},
	computed: {
		...mapState({
			errors: state => state.auth.errors
		})
	},
	created() {
		this.$store.commit(CLEAR_ERRORS);
	},
	methods: {
		async submit() {
			await this.$store.dispatch(LOGIN, this.form);
			this.$store.commit(SET_ACTIVE_MODAL, null);

			window.location.reload(); // todo needs solution without reload
		},
		openResetPasswordModal() {
			this.$store.commit(SET_ACTIVE_MODAL, 'RecoverPasswordModal');
		}
	}
};
</script>
